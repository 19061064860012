<template>
  <div class="b-modal">
    <div class="">
      <div class="d-upload-dropzone cursor-pointer" style="border: none">
        <!--Lower Jaw Canvas-->
        <div
          class="lower-card"
          :class="{
            active: isSelectedJaw === 'lower',
          }"
          ref="card"
        >
          <canvas
            id="canvas"
            class="upper-canvas"
            v-show="isSelectedJaw === 'lower'"
          ></canvas>
        </div>
        <!--Upper Jaw Canvas-->
        <div class="upper-card" ref="cardUpper">
          <canvas
            id="canvasUpper"
            class="upper-canvas"
            v-show="isSelectedJaw === 'upper'"
          ></canvas>
        </div>
        <!--When NO Jaws-->

        <div class="dropzone-check" :class="isSelectedJaw ? 'active' : ''">
          <div class="checkbox-group">
            <input
              type="radio"
              class="checkbox-input"
              id="upper"
              v-model="isSelectedJaw"
              name="jaw-selection"
              value="upper"
              :checked="isSelectedJaw === 'upper'"
            />
            <label for="upper" class="checkbox-label">
              {{ $t("Show Upper Jaw") }}
            </label>
          </div>
          <div class="checkbox-group">
            <input
              type="radio"
              class="checkbox-input"
              id="lower"
              v-model="isSelectedJaw"
              name="jaw-selection"
              value="lower"
              :checked="isSelectedJaw === 'lower'"
            />
            <label for="lower" class="checkbox-label">
              {{ $t("Show Lower Jaw") }}
            </label>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="c-modal-footer">
      <div class="checkbox-group">
        <input
          type="radio"
          v-model="selectedJaw"
          name="jaw-selection"
          value="both"
          class="checkbox-input"
          @change="showJaw"
          id="jaws"
        />
        <label for="jaws" class="checkbox-label">
          {{ $t("Show Both Jaws") }}
        </label>
      </div>
      <div class="checkbox-group">
        <input
          type="radio"
          class="checkbox-input"
          id="upper"
          v-model="selectedJaw"
          name="jaw-selection"
          value="upper"
          @change="showJaw"
        />
        <label for="upper" class="checkbox-label">
          {{ $t("Show Upper Jaw") }}
        </label>
      </div>
      <div class="checkbox-group">
        <input
          type="radio"
          class="checkbox-input"
          id="lower"
          v-model="selectedJaw"
          name="jaw-selection"
          value="lower"
          @change="showJaw"
        />
        <label for="lower" class="checkbox-label">
          {{ $t("Show Lower Jaw") }}
        </label>
      </div>
    </div> -->
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BFormInput,
  BFormRadio,
  BCard,
  BCardText,
  BCardTitle,
  BImg,
  BFormFile,
  BFormGroup,
  BButton,
} from "bootstrap-vue";
import * as THREE from "three";
import { TrackballControls } from "three/examples/jsm/controls/TrackballControls.js";
import { TransformControls } from "three/examples/jsm/controls/TransformControls.js";
import { STLExporter } from "three/examples/jsm/exporters/STLExporter.js";
import NProgress from "nprogress";
import FileService from "@/services/file.service";
import ElementIcon from "@/components/elements/Icon.vue";
import { mapGetters } from "vuex";
import { addLights, loadModel } from "@/utils/3d";

export default {
  props: {
    results: {
      type: Array,
      default: [],
    },
  },
  components: {
    BRow,
    BFormRadio,
    BCol,
    BCard,
    BFormInput,
    BCardText,
    BCardTitle,
    BImg,
    BFormFile,
    BFormGroup,
    BButton,
    ElementIcon,
  },
  computed: {
    ...mapGetters(["showLoader"]),
  },
  watch: {
    sensitivity(val) {
      let sensitivity = parseFloat(val);

      this.updateControlsSensitivity(sensitivity);
    },
    sensitivityUpper(val) {
      let sensitivity = parseFloat(val);

      this.updateControlsSensitivityUpper(sensitivity);
    },
    termsAndConditions(val) {
      if (val == true) {
        this.isDisabled = false;
      } else {
        this.isDisabled = true;
      }
    },
  },
  data() {
    return {
      casesLeft: 0,
      selectedControl: "trackball",
      upperJawFile: null,
      lowerJawFileInfo: null,
      opgFile: null,
      upperJawFileInfo: null,
      lowerJawFile: null,
      opgFileInfo: null,
      opgFileUrl: null,
      loadingDone: false,
      caseName: "",
      caseDetails: "",
      isDragOver: false,
      isErrorMessage: false,
      isDisabled: true,
      termsAndConditions: false,
      scene: null,
      camera: null,
      renderer: null,
      sensitivity: 1,
      controls: null, // Active camera controls
      orbitControls: null,
      trackballControls: null,
      flyControls: null,
      userModel: null,
      groundTruthModel: null,
      userGroup: null,
      transformControl: null,
      clock: null,
      sceneUpper: null,
      cameraUpper: null,
      rendererUpper: null,
      sensitivityUpper: 1,
      controlsUpper: null, // Active camera controls
      orbitControlsUpper: null,
      trackballControlsUpper: null,
      flyControlsUpper: null,
      userModelUpper: null,
      groundTruthModelUpper: null,
      userGroupUpper: null,
      transformControlUpper: null,
      clockUpper: null,
      selectedControlUpper: "trackball",
      isSelectedJaw: "",
      costsPerLowerJaw: 0,
      costsPerUpperJaw: 0,
      credits: 0,
      showModal: false,
    };
  },

  async mounted() {
    this.$store.commit("showLoader", true);
    try {
      this.clock = new THREE.Clock();
      this.clockUpper = new THREE.Clock();
      this.init();
      this.animate();
      this.initUpper();
      this.animateUpper();
      window.addEventListener("resize", this.onWindowResize);
      window.addEventListener("resize", this.onWindowResizeUpper);
      await this.loadData();
    } catch (e) {
      this.$store.commit("showLoader", false);
      console.error(e);
    } finally {
      this.$store.commit("showLoader", false);
    }
    this.$store.commit("showLoader", false);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onWindowResize);
    window.removeEventListener("resize", this.onWindowResizeUpper);
  },
  methods: {
    async loadData() {
      this.isSelectedJaw = "upper";
      let upper, lower;
      if (this.results[0] != null)
        upper = await FileService.getResultFileById(this.results[0]);
      else this.isSelectedJaw = "lower";

      if (this.results[1] != null)
        lower = await FileService.getResultFileById(this.results[1]);

      if (upper) {
        this.previewUpperJaw(upper);
      }
      if (lower) {
        this.previewLowerJaw(lower);
      }
    },
    init() {
      // Scene and Camera
      this.scene = new THREE.Scene();
      addLights(this.scene);
      const card = this.$refs.card;

      const cardWidth = card.clientWidth;
      const cardHeight = card.clientHeight;

      this.camera = new THREE.PerspectiveCamera(
        60,
        cardWidth / cardHeight, // Use card dimensions for aspect ratio
        0.1,
        1000
      );
      this.camera.position.set(0, 0, 100);

      // Renderer
      this.renderer = new THREE.WebGLRenderer({
        antialias: true,
        canvas: document.getElementById("canvas"),
      });
      this.renderer.setSize(cardWidth, cardHeight);

      // Controls
      this.trackballControls = new TrackballControls(
        this.camera,
        this.renderer.domElement
      );

      this.trackballControls.screen.height = cardHeight;
      this.trackballControls.screen.width = cardWidth;

      // Set TrackballControls as default
      this.controls = this.trackballControls;
      this.trackballControls.enabled = true;

      // Adjust TrackballControls sensitivity to make rotate speed comparable to zoom speed
      // Default rotateSpeed is 1, increase it to make rotation faster
      this.trackballControls.rotateSpeed = 2; // Increased from default 1 to 2

      // Similarly, adjust other control sensitivities if needed
      this.updateControlsSensitivity(1);

      // User Model Group
      this.userGroup = new THREE.Group();
      this.scene.add(this.userGroup);
    },
    onWindowResize() {
      const card = this.$refs.card;

      const cardWidth = card.clientWidth;
      const cardHeight = card.clientHeight;

      // Update aspect ratio and camera projection
      this.camera.aspect = cardWidth / cardHeight;
      this.camera.updateProjectionMatrix();

      // Update the renderer size based on the card size
      this.renderer.setSize(cardWidth, cardHeight);
    },
    initUpper() {
      // Scene and Camera
      this.sceneUpper = new THREE.Scene();
      addLights(this.sceneUpper)
      const cardUpper = this.$refs.cardUpper; // Reference the card container for upper model

      const cardWidthUpper = cardUpper.clientWidth;
      const cardHeightUpper = cardUpper.clientHeight;

      this.cameraUpper = new THREE.PerspectiveCamera(
        60,
        cardWidthUpper / cardHeightUpper, // Set aspect ratio based on card dimensions
        0.1,
        1000
      );
      this.cameraUpper.position.set(0, 0, 100);

      // Renderer
      this.rendererUpper = new THREE.WebGLRenderer({
        antialias: true,
        canvas: document.getElementById("canvasUpper"),
      });
      this.rendererUpper.setSize(cardWidthUpper, cardHeightUpper); // Set renderer size based on card size

      // Controls
      this.trackballControlsUpper = new TrackballControls(
        this.cameraUpper,
        this.rendererUpper.domElement
      );

      this.trackballControlsUpper.screen.height = cardWidthUpper;
      this.trackballControlsUpper.screen.width = cardHeightUpper;

      // Set TrackballControls as default
      this.controlsUpper = this.trackballControlsUpper;
      this.trackballControlsUpper.enabled = true;

      // Adjust TrackballControls sensitivity to make rotate speed comparable to zoom speed
      // Default rotateSpeed is 1, increase it to make rotation faster
      this.trackballControlsUpper.rotateSpeed = 2; // Increased from default 1 to 2

      // Similarly, adjust other control sensitivities if needed
      this.updateControlsSensitivityUpper(1);

      // User Model Group
      this.userGroupUpper = new THREE.Group();

      this.userGroupUpper.rotation.y = Math.PI;

      this.sceneUpper.add(this.userGroupUpper);

      // Handle resize events for upper model
      window.addEventListener("resize", this.onWindowResizeUpper);
    },
    onWindowResizeUpper() {
      const cardUpper = this.$refs.cardUpper;

      const cardWidthUpper = cardUpper.clientWidth;
      const cardHeightUpper = cardUpper.clientHeight;

      // Update aspect ratio and renderer size for the upper model
      this.cameraUpper.aspect = cardWidthUpper / cardHeightUpper;
      this.cameraUpper.updateProjectionMatrix();
      this.rendererUpper.setSize(cardWidthUpper, cardHeightUpper);
    },

    animate() {
      requestAnimationFrame(this.animate);
      let delta = this.clock.getDelta();

      if (this.controls === this.flyControls) {
        this.flyControls.update(delta);
      } else {
        this.controls.update();
      }

      this.render();
    },
    animateUpper() {
      requestAnimationFrame(this.animateUpper);
      let delta = this.clockUpper.getDelta();

      if (this.controlsUpper === this.flyControlsUpper) {
        this.flyControlsUpper.update(delta);
      } else {
        this.controlsUpper.update();
      }

      this.renderUpper();
    },
    render() {
      this.renderer.render(this.scene, this.camera);
    },
    renderUpper() {
      this.rendererUpper.render(this.sceneUpper, this.cameraUpper);
    },

    rotateBtn() {
      if (this.transformControl) this.transformControl.setMode("rotate");
    },
    translateBtn() {
      if (this.transformControl) this.transformControl.setMode("translate");
    },
    rotateBtnUpper() {
      if (this.transformControlUpper)
        this.transformControlUpper.setMode("rotate");
    },
    translateBtnUpper() {
      if (this.transformControlUpper)
        this.transformControlUpper.setMode("translate");
    },
    updateControlsSensitivity(sensitivity) {
      // TrackballControls Sensitivity
      this.trackballControls.rotateSpeed = sensitivity; // Increased rotateSpeed
      this.trackballControls.zoomSpeed = sensitivity;
      this.trackballControls.panSpeed = sensitivity;
    },
    updateControlsSensitivityUpper(sensitivity) {
      // TrackballControls Sensitivity
      this.trackballControlsUpper.rotateSpeed = sensitivity; // Increased rotateSpeed
      this.trackballControlsUpper.zoomSpeed = sensitivity;
      this.trackballControlsUpper.panSpeed = sensitivity;
    },
    switchControls() {
      // Disable all controls
      this.orbitControls.enabled = false;
      this.trackballControls.enabled = false;
      this.flyControls.enabled = false;

      let currentControl = this.selectedControl;
      switch (currentControl) {
        case "orbit":
          this.controls = this.orbitControls;
          this.orbitControls.enabled = true;
          break;
        case "trackball":
          this.controls = this.trackballControls;
          this.trackballControls.enabled = true;
          break;
        case "fly":
          this.controls = this.flyControls;
          this.flyControls.enabled = true;
          break;
      }
    },
    switchControlsUpper() {
      // Disable all controls
      this.orbitControlsUpper.enabled = false;
      this.trackballControlsUpper.enabled = false;
      this.flyControlsUpper.enabled = false;

      let currentControl = this.selectedControlUpper;
      switch (currentControl) {
        case "orbit":
          this.controlsUpper = this.orbitControlsUpper;
          this.orbitControlsUpper.enabled = true;
          break;
        case "trackball":
          this.controlsUpper = this.trackballControlsUpper;
          this.trackballControlsUpper.enabled = true;
          break;
        case "fly":
          this.controlsUpper = this.flyControlsUpper;
          this.flyControlsUpper.enabled = true;
          break;
      }
    },

    uploadProgress: function (load_status, load_session) {
      this.loadingDone = false;
      var sumLoading = 0;
      var allToLoad = 0;
      for (var i = 0; i < load_status.length; i++) {
        if (
          typeof load_status[i] === "undefined" ||
          typeof load_status[i]["total"] === "undefined"
        ) {
          continue;
        }
        allToLoad += load_status[i]["total"];
        sumLoading += load_status[i]["loaded"];
      }

      var percentage = sumLoading / allToLoad;
      if (percentage > 0.8) {
        NProgress.set(0.6);
        setTimeout(this.keepProgressbarBusy.bind(this), 1100);
      } else {
        NProgress.set(percentage);
      }
    },

    keepProgressbarBusy: function () {
      if (this.loadingDone == false) {
        NProgress.inc(0.1);
        setTimeout(this.keepProgressbarBusy.bind(this), 300);
      } else {
        NProgress.done();
      }
    },

    uploadData2DTo3D: async function () {
      this.isErrorMessage = true;
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.$store.commit("showLoader", true);
          if (this.upperJawFile != null || this.lowerJawFile != null) {
            this.sumCredits =
              this.upperJawFile && this.lowerJawFile
                ? parseFloat(this.costsPerUpperJaw) +
                  parseFloat(this.costsPerLowerJaw)
                : this.upperJawFile
                ? parseFloat(this.costsPerUpperJaw)
                : parseFloat(this.costsPerLowerJaw);

            if (this.sumCredits > parseFloat(this.credits)) {
              this.$store.commit("showLoader", false);
              this.showModal = true;
            } else {
              this.isDisabled = true;
              let uploadPromises = [];
              if (this.upperJawFile != null) {
                let exporter = new STLExporter();
                let result = exporter.parse(this.userModelUpper);
                // Encode the result to binary format (Uint8Array)
                let encoder = new TextEncoder();
                let binaryData = encoder.encode(result);
                // Create a Blob object from the binary data
                let blob = new Blob([binaryData], {
                  type: "application/octet-stream",
                });
                // Create a File object from the Blob
                let fileUpper = new File([blob], this.upperJawFile.name, {
                  type: "application/octet-stream", // MIME type for STL
                  lastModified: new Date(2024, 3, 29, 16, 46, 36).getTime(), // Last modified date as timestamp
                });
                // Push the upper jaw upload promise to the array
                uploadPromises.push(
                  FileService.uploadFile(
                    fileUpper,
                    { visibility: 0 },
                    0,
                    0.33
                  ).then(
                    function (response) {
                      this.upperJawFileInfo = response.data[0];
                    }.bind(this)
                  )
                );
              }
              if (this.lowerJawFile != null) {
                let exporter = new STLExporter();
                let result = exporter.parse(this.userModel);
                // Encode the result to binary format (Uint8Array)
                let encoder = new TextEncoder();
                let binaryData = encoder.encode(result);
                // Create a Blob object from the binary data
                let blob = new Blob([binaryData], {
                  type: "application/octet-stream",
                });
                // Create a File object from the Blob
                let file = new File([blob], this.lowerJawFile.name, {
                  type: "application/octet-stream", // MIME type for STL
                  lastModified: new Date(2024, 3, 29, 16, 46, 36).getTime(), // Last modified date as timestamp
                });
                // Push the lower jaw upload promise to the array
                uploadPromises.push(
                  FileService.uploadFile(
                    file,
                    { visibility: 0 },
                    0.33,
                    0.33
                  ).then(
                    function (response) {
                      this.lowerJawFileInfo = response.data[0];
                    }.bind(this)
                  )
                );
              }
              // Wait for all uploads to complete
              Promise.all(uploadPromises).then(() => {
                this.createCase();
                this.$store.commit("showLoader", false);
              });
            }
          }
        } else {
          this.$store.commit("showLoader", false);
        }
      });
    },

    createCase() {
      var data = {
        files: {
          upper: this.upperJawFileInfo?.id ?? null,
          lower: this.lowerJawFileInfo?.id ?? null,
        },
        extensions: [{ id: "opg-to-3d" }],
        details: this.caseDetails,
        name: this.caseName,
        sumCredits: this.sumCredits,
      };
      this.$store
        .dispatch("caseLists/create", data)
        .then(
          function (response) {
            this.$store.dispatch("customers/getCredits");
            this.$router.push({ path: "/my-cases/active" });
          }.bind(this)
        )
        .finally(() => {
          this.termsAndConditions = false;
          this.upperJawFileInfo = null;
          this.lowerJawFileInfo = null;
        });
    },

    uploadDone: function () {
      NProgress.done();
      this.isDisabled = false;
      this.loadingDone = true;
    },
    detectFileType(data) {
      if (typeof data === "string") {
        // Check for ASCII STL file
        if (data.includes("STL file") || data.startsWith("solid")) {
          return "stl";
        }

        // Check for OBJ file
        if (
          data.startsWith("#") ||
          data.startsWith("o ") ||
          data.startsWith("v ")
        ) {
          return "obj";
        }

        // Check for ASCII PLY file
        if (data.startsWith("ply")) {
          return "ply";
        }
      } else if (data instanceof ArrayBuffer || data instanceof Uint8Array) {
        // Binary data handling (for binary STL or binary PLY)
        const firstBytes = new TextDecoder().decode(data.slice(0, 5));
        // Check for Binary STL
        if (!firstBytes.startsWith("solid")) {
          return "stl";
        }
        // Check for Binary PLY by looking for the 'ply' keyword in the first few bytes
        if (firstBytes.startsWith("ply")) {
          return "ply"; // Binary PLY
        }
      }
      // likely binary STL file
      return "stl";
    },
    previewUpperJaw(upper) {
      this.isSelectedJaw = "upper";
      let fileContent = upper.data;
      if (!fileContent) return;
      this.upperJawFile = fileContent;

      let extension = this.detectFileType(fileContent);
      if (!extension || extension === "unknown") {
        console.log("Unsupported file format");
        return;
      }

      if (this.userModelUpper) {
        this.userGroupUpper.remove(this.userModelUpper);
      }

      this.userModelUpper = loadModel(fileContent, extension, {
        side: THREE.DoubleSide,
        color: 0xb1bcbc
      });
      
      this.userGroupUpper.add(this.userModelUpper);

      // Add Transform Controls
      this.addTransformControlsUpper(this.userModelUpper);
    },
    addTransformControls(object) {
      // Remove existing controls
      if (this.transformControl) {
        this.scene.remove(this.transformControl);
        this.transformControl.dispose();
      }
      // Transform Controls
      this.transformControl = new TransformControls(
        this.camera,
        this.renderer.domElement
      );
      this.transformControl.attach(object);
      this.scene.add(this.transformControl);
      this.transformControl.addEventListener("change", () => {
        this.render;
      });
      this.transformControl.addEventListener("dragging-changed", (event) => {
        this.controls.enabled = !event.value;
      });

      // Set initial mode to translate
      this.transformControl.setMode("translate");
    },
    addTransformControlsUpper(object) {
      // Remove existing controls
      if (this.transformControlUpper) {
        this.sceneUpper.remove(this.transformControlUpper);
        this.transformControlUpper.dispose();
      }
      // Transform Controls
      this.transformControlUpper = new TransformControls(
        this.cameraUpper,
        this.rendererUpper.domElement
      );
      this.transformControlUpper.attach(object);
      this.sceneUpper.add(this.transformControlUpper);
      this.transformControlUpper.addEventListener("change", () => {
        this.renderUpper;
      });
      this.transformControlUpper.addEventListener(
        "dragging-changed",
        (event) => {
          this.controlsUpper.enabled = !event.value;
        }
      );

      // Set initial mode to translate
      this.transformControlUpper.setMode("translate");
    },

    previewLowerJaw(lower) {
      let fileContent = lower.data;
      if (!fileContent) return;
      this.lowerJawFile = fileContent;
      let extension = this.detectFileType(fileContent);
      if (!extension || extension === "unknown") {
        console.log("Unsupported file format");
        return;
      }
      if (this.userModel) {
        this.userGroup.remove(this.userModel);
      }

      this.userModel = loadModel(fileContent, extension, {
        side: THREE.DoubleSide,
        color: 0xb1bcbc
      });

      this.userGroup.add(this.userModel);

      this.addTransformControls(this.userModel);
    },
    centerObject(object) {
      // Compute bounding box
      const box = new THREE.Box3().setFromObject(object);
      // Compute center
      const center = box.getCenter(new THREE.Vector3());
      // Reposition object so that its center is at the origin
      object.position.sub(center);
    },
    deleteUpperJawFile() {
      this.upperJawFile = null;
      this.userGroupUpper.remove(this.userModelUpper);
    },

    deleteLowerJawFile() {
      this.lowerJawFile = null;
      this.userGroup.remove(this.userModel);
    },
    openUpperJawFilePicker() {
      this.$refs.fileUpperJawInput.click();
    },
    openLowerJawFilePicker() {
      this.$refs.fileLowerJawInput.click();
    },
    onDragOver() {
      this.isDragOver = true;
    },
    onDragLeave() {
      this.isDragOver = false;
    },
    onDrop(event) {
      this.isDragOver = false;
      const droppedFiles = event.dataTransfer.files;
      if (droppedFiles && droppedFiles.length > 0) {
        // Directly call previewUpperJaw with the dropped file
        this.previewUpperJaw({ target: { files: droppedFiles } });
      }
    },
    onDropLower(event) {
      this.isDragOver = false;
      const droppedFiles = event.dataTransfer.files;
      if (droppedFiles && droppedFiles.length > 0) {
        // Call previewLowerJaw with the dropped file
        this.previewLowerJaw({ target: { files: droppedFiles } });
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    document.body.classList.add("cookie-layout");
    next();
  },
  beforeRouteLeave(to, from, next) {
    document.body.classList.remove("cookie-layout");
    next();
  },
};
</script>

<style>
.lower-card,
.upper-card {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.lower-card.active {
  z-index: 1;
}
.upper-canvas {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  left: 0;
  top: 0;
}
</style>
